import Select from 'react-select'

export default function Dropdown({
  labelText,
  placeholder,
  dropDownOptions,
  onChange,
  value,
  ref,
  name,
  errorText,
  defaultValue,
  ...props
}) {
  const customStyles = (value) => ({
    control: (base) => ({
      boxShadow: 'none',
      border: errorText ? '1px solid rgb(255 0 0)' : '1px solid #C9C9C9',
      '&:hover': {
        border: '1px solid #C9C9C9'
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0.1rem 0.1rem',
      borderRadius: 8,
      color: '#C9C9C9',
      backgroundColor: '#fafafa',
      width: '100%',
      fontSize: '0.8rem'
      // backgroundColor: value ? '#F76A18' : '#c9c9c9'
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? '#f76a18'
          : isSelected
          ? '#c9c9c9'
          : 'transparent'
      }
    }
  })

  return (
    <section className='w-full flex flex-col'>
      <label
        htmlFor={labelText}
        id={labelText}
        className='text-base lg:text-lg text-priColor mb-2 font-bold leading-relaxed inline-block'
      >
        {labelText}
      </label>
      <Select
        name={name}
        inputRef={ref}
        defaultValue={defaultValue || null}
        value={value}
        onChange={onChange}
        aria-label={labelText}
        aria-labelledby={labelText}
        placeholder={placeholder}
        options={dropDownOptions}
        styles={customStyles()}
        getOptionLabel={(option) => option.label || option.display_name}
        getOptionValue={(option) => option.value || option.terminal_id}
        components={{ IndicatorSeparator: () => null }}
      />
    </section>
  )
}
